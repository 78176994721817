import { reCreateButton } from '../components/button';
import { CustomizationOptionsInterface } from '../types/options';

export class WidgetOptions {
  static customizationOptions: CustomizationOptionsInterface = {
    color: '#5555D8',
    submitText: 'Send',
    titleSatisfaction: 'Give us your feedback',
    placeholderFeedback: '💡 Describe your needs...',
    titleContact: 'We will keep you updated',
    placeholderContact: 'email@domain.com',
    token: '',
    testingMode: false
  };

  constructor() {
    throw new Error('WidgetOptions is a static class');
  }

  static setOptions(options: Partial<CustomizationOptionsInterface>): void {
    this.customizationOptions = {
      ...this.customizationOptions,
      ...options
    };

    reCreateButton();
  }
}
