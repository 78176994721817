import { init } from '..';
import { WidgetOptions } from '../context/widgetOptions';
import { WidgetState } from '../context/widgetState';

export const createModal = () => {
  const modalContainerOld = document.getElementById(
    'gravite_widget_modal_container'
  ) as HTMLElement;

  if (modalContainerOld) {
    modalContainerOld.remove();
  }

  const modalContainer = document.createElement('div');
  modalContainer.id = 'gravite_widget_modal_container';

  const modal = document.createElement('div');
  modal.id = 'gravite_widget_modal';
  modal.addEventListener('click', e => {
    e.stopPropagation();
    e.preventDefault();
  });

  const modalContent = document.createElement('div');
  modalContent.id = 'gravite_widget_modal_content';

  const closeButton = document.createElement('button');
  closeButton.id = 'gravite_widget_modal_close_button';
  closeButton.classList.add('close-button');
  closeButton.classList.add('gravite_widget_close_button');
  closeButton.innerHTML = `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="gravite_widget_close_button">
  <path d="M11.5 1.5L1.5 11.5M1.5 1.5L11.5 11.5" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`;
  closeButton.addEventListener('click', e => {
    e.stopPropagation();
    e.preventDefault();
    closeModal();
  });

  const poweredByContainer = document.createElement('div');
  poweredByContainer.classList.add('powered-by-container');
  poweredByContainer.style.cursor = 'pointer';

  poweredByContainer.addEventListener('click', e => {
    e.stopPropagation();
    e.preventDefault();
    window.open('https://gravite.io/real-time-feedback-tool', '_blank');
  });

  const graviteLogo = document.createElement('div');
  graviteLogo.classList.add('gravite-logo');
  graviteLogo.innerHTML = `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_dd_15952_447859)">
  <circle cx="6.4843" cy="5.48497" r="0.914772" transform="rotate(-14.0344 6.4843 5.48497)" fill="#3535F3"/>
  <path d="M8.34836 3.50282C8.63721 3.19583 8.62532 2.70398 8.26289 2.48874C7.76814 2.1949 7.20469 2.02607 6.62182 2.00291C5.78271 1.96957 4.95978 2.24038 4.30445 2.76551C3.64912 3.29064 3.20547 4.03476 3.05513 4.86097C2.90479 5.68718 3.05787 6.53988 3.48621 7.2622C3.91455 7.98452 4.58934 8.52786 5.3864 8.79224C6.18347 9.05663 7.04921 9.02428 7.82432 8.70114C8.59944 8.37801 9.23179 7.78583 9.60503 7.03356C9.86429 6.511 9.98641 5.93562 9.9659 5.36056C9.95088 4.93931 9.52501 4.69294 9.11709 4.79914C8.70917 4.90535 8.48058 5.33082 8.42382 5.7485C8.39537 5.95785 8.33302 6.16285 8.23762 6.35512C8.02788 6.77786 7.67253 7.11063 7.23696 7.29222C6.80139 7.4738 6.31489 7.49198 5.86698 7.34341C5.41907 7.19484 5.03988 6.88951 4.79917 6.48361C4.55847 6.0777 4.47244 5.59853 4.55693 5.13424C4.64141 4.66996 4.89072 4.2518 5.25898 3.95671C5.62724 3.66161 6.08968 3.50943 6.56122 3.52817C6.77568 3.53669 6.98547 3.5803 7.18281 3.65576C7.57653 3.8063 8.05951 3.80981 8.34836 3.50282Z" fill="#3535F3"/>
  </g>
  <defs>
  <filter id="filter0_dd_15952_447859" x="0" y="0" width="13" height="13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15952_447859"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="1.5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"/>
  <feBlend mode="normal" in2="effect1_dropShadow_15952_447859" result="effect2_dropShadow_15952_447859"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_15952_447859" result="shape"/>
  </filter>
  </defs>
  </svg>
  `;

  const smallPoweredBy = document.createElement('small');
  smallPoweredBy.classList.add('powered-by');
  smallPoweredBy.innerText = 'Powered by ';

  const poweredBy = document.createElement('a');
  poweredBy.href = 'https://gravite.io/real-time-feedback-tool';
  poweredBy.target = '_blank';
  poweredBy.innerText = 'Gravite';

  smallPoweredBy.appendChild(poweredBy);

  poweredByContainer.appendChild(graviteLogo);
  poweredByContainer.appendChild(smallPoweredBy);
  // from index.css

  const style = document.createElement('style');
  style.setAttribute('rel', 'stylesheet');
  style.setAttribute('type', 'text/css');
  style.innerHTML = `
  :host * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }
  
  :host .close-button {
    position: absolute;
    right: 16px;
    top: -10px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    width: 27px;
  }
  
  :host .powered-by-container {
    position: absolute;
    bottom: 3px;
    left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  :host .powered-by-container .gravite-logo {
    height: 15px;
  }
  
  :host .powered-by-container .gravite-logo svg {
    vertical-align: baseline;
  }
  
  :host .powered-by {
    font-size: 10px;
    line-height: 17px;
    color: #a0aec0;
  }
  :host .powered-by a {
    font-size: 10px;
    line-height: 17px;
    color: #a0aec0;
    text-decoration: underline;
  }
  
  #gravite_widget_modal_content {
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    overflow: hidden;
    transition: transform 3s ease-in-out;
  }
  /* SATISFACTION STEP */
  #gravite_widget_modal_content .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    overflow: hidden;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
  
  #gravite_widget_modal_content .satisfaction-select {
    width: 100%;
    transition: all 0.3s ease-in-out;
    transform: translateY(70%);
  }
  
  #gravite_widget_modal_content .satisfaction-select .flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 12px;
  }
  
  #gravite_widget_modal_content .satisfaction-select .flex-container .flex-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 45px;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
  
  #gravite_widget_modal_content
    .satisfaction-select
    .flex-container
    .flex-item
    > div:first-child {
    font-size: 30px;
    line-height: 38px;
    font-weight: 400;
  }
  
  #gravite_widget_modal_content
    .satisfaction-select
    .flex-container
    .flex-item:hover
    > div:last-child {
    visibility: visible;
  }
  
  #gravite_widget_modal_content
    .satisfaction-select
    .flex-container
    .flex-item
    > div:last-child {
    visibility: hidden;
    font-size: 12px;
    color: #a0aec0;
  }
  
  /* FEEDBACK STEP */
  #gravite_widget_modal_content .feedback-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 75%;
    position: relative;
  }
  
  #gravite_widget_modal_content .feedback-step .feedback-textarea {
    width: 281px;
    height: 98px;
    resize: none;
    padding: 10px 14px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin-top: 5px;
  }
  
  #gravite_widget_modal_content .feedback-step .submit-button {
    color: #ffffff;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 5px;
    padding: 5px 14px;
    border-radius: 8px;
    opacity: 1;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
  
  #gravite_widget_modal_content .feedback-step .submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* CONTACT STEP */
  #gravite_widget_modal_content .contact-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75%;
    position: relative;
  }
  
  #gravite_widget_modal_content .contact-step .input-field {
    width: 281px;
    height: 40px;
    padding: 0 3px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin-top: 5px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  #gravite_widget_modal_content .contact-step .input-field input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    border: none;
  }
  
  #gravite_widget_modal_content .contact-step .input-field input::placeholder {
    font-size: 12px;
    font-weight: 400;
  }
  
  #gravite_widget_modal_content .contact-step .button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  #gravite_widget_modal_content .contact-step .skip-button,
  #gravite_widget_modal_content .contact-step .send-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px 14px;
    border-radius: 8px;
  }
  
  #gravite_widget_modal_content .contact-step .skip-button {
    color: #98a2b3;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  
  #gravite_widget_modal_content .contact-step .skip-button:active {
    box-shadow: none;
  }
  
  #gravite_widget_modal_content .contact-step .send-button {
    color: #ffffff;
    background-color: #5555d8;
  }
  #gravite_widget_modal_content .contact-step .send-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* THANK STEP */
  
  #gravite_widget_modal_content #thanks-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 100%;
  }
  
  #gravite_widget_modal_content #thanks-step .thanks-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
  `;

  const shadowRoot = modal.attachShadow({ mode: 'open' });
  shadowRoot.appendChild(style);
  shadowRoot.appendChild(closeButton);
  shadowRoot.appendChild(modalContent);
  shadowRoot.appendChild(poweredByContainer);
  modalContainer.appendChild(modal);
  document.body.appendChild(modalContainer);
};

export const openModal = () => {
  const modalContainer = document.getElementById(
    'gravite_widget_modal_container'
  ) as HTMLElement;
  const modal = document.getElementById('gravite_widget_modal') as HTMLElement;
  const buttonContainer = document.getElementById(
    'gravite_widget_button_container'
  ) as HTMLElement;

  if (modal && modalContainer && buttonContainer) {
    modalContainer.style.display = 'flex';
    modalContainer.addEventListener('click', e => {
      e.stopPropagation();
      e.preventDefault();
      closeModal();
    });
    setTimeout(() => {
      buttonContainer.classList.add('translate');
      modal.classList.add('translate');
    }, 10);
  }
};

export const closeModal = () => {
  const modalContainer = document.getElementById(
    'gravite_widget_modal_container'
  ) as HTMLElement;
  const modal = document.getElementById('gravite_widget_modal') as HTMLElement;

  const buttonContainer = document.getElementById(
    'gravite_widget_button_container'
  ) as HTMLElement;

  WidgetState.step = 1;
  WidgetState.satisfaction = 0;
  WidgetState.feedback = '';
  WidgetState.email = '';

  if (modal && modalContainer && buttonContainer) {
    modalContainer.onclick = null;
    buttonContainer.classList.remove('translate');
    modal.classList.remove('translate');
    setTimeout(() => {
      modalContainer.style.display = 'none';
      init(WidgetOptions.customizationOptions);
    }, 300);
  }
};

export const shadowRoot = (): ShadowRoot => {
  const modal = document.getElementById('gravite_widget_modal') as HTMLElement;
  return modal.shadowRoot as ShadowRoot;
};
