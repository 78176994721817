import { WidgetState } from '../context/widgetState';

import { shadowRoot } from './modal';

const setSelected = (value: any) => {
  WidgetState.satisfaction = value;

  const flexItems = shadowRoot().querySelectorAll(
    '.satisfaction-select .flex-item'
  ) as NodeListOf<HTMLElement>;
  flexItems.forEach((item, index) => {
    if (index + 1 === value) {
      item.style.opacity = '1';
    } else {
      item.style.opacity = '0.3';
    }
  });
};

const handleClick = (e: any, i: number) => {
  e.stopPropagation();
  e.preventDefault();
  setSelected(i + 1);
  transition();
  setTimeout(() => {
    WidgetState.changeStep(2);
  }, 500);
};

const transition = () => {
  const titleSatisfaction = shadowRoot().querySelector(
    '.title-satisfaction'
  ) as HTMLElement;

  const satisfactionSelect = shadowRoot().querySelector(
    '.satisfaction-select'
  ) as HTMLElement;

  titleSatisfaction.style.height = '0';
  satisfactionSelect.style.transform = 'translateY(0)';
  satisfactionSelect.style.height = 'fit-content';

  const flexContainer = shadowRoot().querySelector(
    '.satisfaction-select .flex-container'
  ) as HTMLElement;
  flexContainer.style.justifyContent = 'flex-start';
  flexContainer.style.gap = '12px';

  const flexItems = shadowRoot().querySelectorAll(
    '.satisfaction-select .flex-container .flex-item'
  ) as NodeListOf<HTMLElement>;
  flexItems.forEach(item => {
    item.style.width = '24px';
    const emoji = item.firstChild as HTMLElement;
    emoji.style.fontSize = '24px';
    emoji.style.lineHeight = '32px';
  });

  setTimeout(() => {
    titleSatisfaction.style.display = 'none';
    const flexItems = shadowRoot().querySelectorAll(
      '.satisfaction-select .flex-container .flex-item div:nth-child(2)'
    ) as NodeListOf<HTMLElement>;
    flexItems.forEach(item => {
      item.style.display = 'none';
    });
  }, 500);
};

export const createSatisfactionStep = () => {
  const satisfactionSelect = document.createElement('div');
  satisfactionSelect.classList.add('satisfaction-select');

  const flexContainer = document.createElement('div');
  flexContainer.classList.add('flex-container');
  satisfactionSelect.appendChild(flexContainer);

  const emojis = ['😩', '🙁', '😐', '🙂', '😍'];
  const labels = ['Hate', 'Dislike', 'Neutral', 'Like', 'Love'];

  for (let i = 0; i < emojis.length; i++) {
    const flexItem = document.createElement('div');
    flexItem.classList.add('flex-item');

    const emojiText = document.createElement('div');
    emojiText.textContent = emojis[i] as string;
    flexItem.appendChild(emojiText);

    const labelText = document.createElement('div');
    labelText.textContent = labels[i] as string;
    flexItem.appendChild(labelText);

    flexItem.addEventListener('click', e => {
      handleClick(e, i);
    });

    flexContainer.appendChild(flexItem);
  }

  setSelected(0);

  return satisfactionSelect;
};
