import { WidgetOptions } from '../context/widgetOptions';
import { WidgetState } from '../context/widgetState';

import { shadowRoot } from './modal';

export const focusTextarea = () => {
  const feedbackTextarea = shadowRoot().querySelector(
    '#gravite_widget_feedback_step .feedback-textarea'
  ) as HTMLTextAreaElement;
  feedbackTextarea.focus();
};

export const hideFeedbackStep = () => {
  const feedbackStep = shadowRoot().querySelector(
    '#gravite_widget_feedback_step'
  ) as HTMLElement;
  const satisfactionStep = shadowRoot().querySelector(
    '#gravite_widget_modal_content .satisfaction-select'
  ) as HTMLElement;

  const titleSatisfaction = shadowRoot().querySelector(
    '#gravite_widget_modal_content .title-satisfaction'
  ) as HTMLElement;

  if (feedbackStep && satisfactionStep && titleSatisfaction) {
    feedbackStep.style.display = 'none';
    satisfactionStep.style.display = 'none';
    titleSatisfaction.style.display = 'none';
  }
};

const transition = () => {
  const modalContent = shadowRoot().querySelector(
    '#gravite_widget_modal_content'
  ) as HTMLElement;

  const modalContentChilds = modalContent.children;

  for (let i = 0; i < modalContentChilds.length; i++) {
    const element = modalContentChilds[i] as HTMLElement;
    element.style.transition = 'opacity 0.3s ease-in-out';
    // element.style.transform = 'translateY(-200%)';
    // fade out
    element.style.opacity = '0';
  }
};

export const createFeedbackStep = () => {
  const feedbackStepOld = shadowRoot().querySelector(
    '#gravite_widget_feedback_step'
  ) as HTMLElement;
  if (feedbackStepOld) {
    feedbackStepOld.remove();
  }

  const feedbackStep = document.createElement('div');
  feedbackStep.id = 'gravite_widget_feedback_step';
  feedbackStep.classList.add('feedback-step');

  const submitButton = document.createElement('button');
  submitButton.classList.add('submit-button');
  submitButton.textContent = WidgetOptions.customizationOptions.submitText;
  submitButton.type = 'button';
  submitButton.style.backgroundColor = WidgetOptions.customizationOptions.color;

  submitButton.disabled = true;

  const feedbackTextarea = document.createElement('textarea');
  feedbackTextarea.classList.add('feedback-textarea');
  feedbackTextarea.placeholder =
    WidgetOptions.customizationOptions.placeholderFeedback;

  feedbackTextarea.onclick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  feedbackTextarea.addEventListener('input', e => {
    const target = e.target as HTMLTextAreaElement;
    submitButton.disabled = target.value.length <= 0;
  });

  submitButton.onclick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    WidgetState.feedback = feedbackTextarea.value;
    transition();
    WidgetState.changeStep(3);
  };

  feedbackStep.appendChild(feedbackTextarea);
  feedbackStep.appendChild(submitButton);

  return feedbackStep;
};
