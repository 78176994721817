import { WidgetOptions } from '../context/widgetOptions';
import { WidgetState } from '../context/widgetState';

import { shadowRoot } from './modal';

export const focusInput = () => {
  const feedbackTextarea = shadowRoot().querySelector(
    '#gravite_widget_modal_content #contact-step .input-field input'
  ) as HTMLInputElement;
  feedbackTextarea.focus();
};

export const hideContactStep = () => {
  const contactStep = shadowRoot().querySelector(
    '#gravite_widget_modal_content #contact-step'
  ) as HTMLElement;

  const titleContact = shadowRoot().querySelector(
    '#gravite_widget_modal_content .title-contact'
  ) as HTMLElement;

  if (contactStep && titleContact) {
    contactStep.style.display = 'none';
    titleContact.style.display = 'none';
  }
};

const transition = () => {
  const modalContent = shadowRoot().querySelector(
    '#gravite_widget_modal_content'
  ) as HTMLElement;

  const modalContentChilds = modalContent.children;

  for (let i = 0; i < modalContentChilds.length; i++) {
    const element = modalContentChilds[i] as HTMLElement;
    element.style.transition = 'opacity 0.3s ease-in-out';
    element.style.opacity = '0';
  }
};

const setCookie = (value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `gravite_widget_email=${value}${expires}; path=/`;
};

const getCookie = (name: string): string | undefined => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    if (c) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    } else {
      return undefined;
    }
  }
  return undefined;
};

// send feedback to https://api.gravite.io/api/widget/feedbacks with header X-TOKEN
const sendFeedback = async (skip: boolean) => {
  const completeFeedback: {
    description: string;
    email?: string;
    rate: number;
  } = {
    description: WidgetState.feedback,
    rate: WidgetState.satisfaction
  };

  if (!skip && WidgetState.email) {
    completeFeedback.email = WidgetState.email;
  }

  let url = '';

  if (WidgetOptions.customizationOptions.testingMode) {
    url = `https://api.esylva.com/api/widget/feedbacks`;
  } else {
    url = `https://api.gravite.io/api/widget/feedbacks`;
  }

  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Token': WidgetOptions.customizationOptions.token
    },
    body: JSON.stringify(completeFeedback)
  });
};

const handleClick = async (skip: boolean) => {
  const buttonSubmit = shadowRoot().querySelector(
    '#gravite_widget_modal_content #contact-step .send-button'
  ) as HTMLButtonElement;
  const buttonSkip = shadowRoot().querySelector(
    '#gravite_widget_modal_content #contact-step .skip-button'
  ) as HTMLButtonElement;
  buttonSkip.disabled = true;
  buttonSubmit.disabled = true;

  transition();
  await sendFeedback(skip).finally(() => {
    setTimeout(async () => {
      WidgetState.changeStep(4);
    }, 300);
  });
};

const handleInput = (event: Event) => {
  const inputElement = event.target as HTMLInputElement;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const buttonSubmit = shadowRoot().querySelector(
    '#gravite_widget_modal_content #contact-step .send-button'
  ) as HTMLButtonElement;

  if (inputElement.value.match(emailRegex)) {
    buttonSubmit.disabled = false;
    WidgetState.email = inputElement.value;
  } else {
    buttonSubmit.disabled = true;
  }
};

export const createContactStep = () => {
  const contactStep = document.createElement('div');
  contactStep.id = 'contact-step';
  contactStep.classList.add('contact-step');

  const inputField = document.createElement('div');
  inputField.classList.add('input-field');

  const inputElement = document.createElement('input');
  inputElement.type = 'text';
  inputElement.placeholder =
    WidgetOptions.customizationOptions.placeholderContact;
  const email = getCookie('gravite_widget_email') || '';
  inputElement.value = email;
  WidgetState.email = email;
  inputElement.addEventListener('input', handleInput);

  const buttonContainer = document.createElement('div');
  buttonContainer.classList.add('button-container');

  const skipButton = document.createElement('button');
  skipButton.classList.add('skip-button');
  skipButton.textContent = 'Skip';
  skipButton.type = 'button';

  const sendButton = document.createElement('button');
  sendButton.classList.add('send-button');
  sendButton.textContent = WidgetOptions.customizationOptions.submitText;
  sendButton.type = 'button';
  sendButton.style.backgroundColor = WidgetOptions.customizationOptions.color;

  if (
    inputElement.value &&
    inputElement.value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  ) {
    sendButton.disabled = false;
  } else {
    sendButton.disabled = true;
  }

  skipButton.addEventListener('click', () => {
    handleClick(true);
  });

  sendButton.addEventListener('click', () => {
    const inputVal = shadowRoot().querySelector(
      '#gravite_widget_modal_content #contact-step .input-field input'
    ) as HTMLInputElement;

    setCookie(inputVal.value, 7);

    handleClick(false);
  });

  inputField.appendChild(inputElement);
  inputField.appendChild(skipButton);
  inputField.appendChild(sendButton);

  contactStep.appendChild(inputField);

  return contactStep;
};
