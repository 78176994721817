import { createButton } from './components/button';
import { createModal } from './components/modal';
import { WidgetOptions } from './context/widgetOptions';
import { WidgetState } from './context/widgetState';
import { CustomizationOptionsInterface } from './types/options';
import './styles.css';

export const init = (options: Partial<CustomizationOptionsInterface>) => {
  if (options && Object.keys(options).length > 0) {
    WidgetOptions.setOptions(options);
    if (!options.token) {
      console.error(
        'Gravite Widget: You must provide a token to use the widget'
      );
    }
  } else {
    const dataset = document.currentScript?.dataset;
    const datasetOptions: Partial<CustomizationOptionsInterface> = {};
    if (dataset) {
      dataset.color && (datasetOptions.color = dataset.color);
      dataset.submitText &&
        (datasetOptions.submitText =
          dataset.submitText.charAt(0).toUpperCase() +
          dataset.submitText.slice(1));
      dataset.titleSatisfaction &&
        (datasetOptions.titleSatisfaction =
          dataset.titleSatisfaction.charAt(0).toUpperCase() +
          dataset.titleSatisfaction.slice(1));
      dataset.placeholderFeedback &&
        (datasetOptions.placeholderFeedback =
          dataset.placeholderFeedback.charAt(0).toUpperCase() +
          dataset.placeholderFeedback.slice(1));
      dataset.titleContact &&
        (datasetOptions.titleContact =
          dataset.titleContact.charAt(0).toUpperCase() +
          dataset.titleContact.slice(1));
      dataset.placeholderContact &&
        (datasetOptions.placeholderContact =
          dataset.placeholderContact.charAt(0).toUpperCase() +
          dataset.placeholderContact.slice(1));
      if (!dataset.token) {
        console.error(
          'Gravite Widget: You must provide a token to use the widget'
        );
      } else {
        datasetOptions.token = dataset.token;
      }

      if (dataset.testingMode) {
        datasetOptions.testingMode = Boolean(dataset.testingMode);
      }
    }

    WidgetOptions.setOptions(datasetOptions);
  }

  createButton();
  createModal();

  WidgetState.changeStep(1);
};

init({});
