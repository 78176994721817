import { WidgetOptions } from '../context/widgetOptions';

import { openModal } from './modal';

export const createButton = () => {
  const buttonContainer = document.getElementById(
    'gravite_widget_button_container'
  );
  if (buttonContainer) {
    buttonContainer.remove();
  }

  const container = document.createElement('div');
  container.classList.add('button-container');
  container.id = 'gravite_widget_button_container';

  const buttonText = document.createElement('span');
  buttonText.innerText = 'Feedback';

  const button = document.createElement('button');
  button.id = 'gravite_widget_button';
  button.style.backgroundColor = WidgetOptions.customizationOptions.color;

  const buttonIcon = document.createElement('div');
  buttonIcon.classList.add('button-icon');
  buttonIcon.innerHTML = `
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 1.5C11.942 1.5 12.3659 1.67559 12.6785 1.98816C12.9911 2.30072 13.1667 2.72464 13.1667 3.16667L13.1667 13.1667L16.5 16.5L3.16667 16.5C2.72464 16.5 2.30072 16.3244 1.98816 16.0118C1.67559 15.6993 1.5 15.2754 1.5 14.8333L1.5 3.16667C1.5 2.72464 1.67559 2.30072 1.98815 1.98816C2.30072 1.6756 2.72464 1.5 3.16667 1.5L11.5 1.5Z" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

  button.appendChild(buttonIcon);
  button.appendChild(buttonText);

  button.onclick = openModal;

  container.appendChild(button);
  document.body.appendChild(container);
};

export const reCreateButton = () => {
  const buttonContainer = document.getElementById(
    'gravite_widget_button_container'
  );
  if (buttonContainer) {
    buttonContainer.remove();
  }
  createButton();
};
