import {
  createContactStep,
  focusInput,
  hideContactStep
} from '../components/contactStep';
import {
  createFeedbackStep,
  focusTextarea,
  hideFeedbackStep
} from '../components/feedbackStep';
import { closeModal, shadowRoot } from '../components/modal';
import { createSatisfactionStep } from '../components/satisfactionStep';
import { createThanksStep } from '../components/thanksStep';
import { createTitle } from '../components/title';

import { WidgetOptions } from './widgetOptions';

export class WidgetState {
  static step = 0;
  static satisfaction = 0;
  static feedback = '';
  static email = '';

  constructor() {
    throw new Error('FormContext is a static class');
  }

  static changeStep(step: number) {
    WidgetState.step = step;
    const modalContent = shadowRoot().querySelector(
      '#gravite_widget_modal_content'
    ) as HTMLElement;

    switch (step) {
      case 0:
        break;
      case 1:
        modalContent.appendChild(
          createTitle(
            WidgetOptions.customizationOptions.titleSatisfaction,
            'satisfaction'
          )
        );
        modalContent.appendChild(createSatisfactionStep());
        break;
      case 2:
        modalContent.appendChild(createFeedbackStep());
        focusTextarea();

        break;
      case 3:
        setTimeout(() => {
          hideFeedbackStep();
          modalContent.appendChild(
            createTitle(
              WidgetOptions.customizationOptions.titleContact,
              'contact'
            )
          );
          modalContent.appendChild(createContactStep());
          focusInput();
        }, 300);
        break;
      case 4:
        hideContactStep();
        setTimeout(() => {
          modalContent.appendChild(createThanksStep());
        }, 500);

        setTimeout(() => {
          closeModal();
        }, 2000);
        break;
      default:
        break;
    }
  }
}
